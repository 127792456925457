export function formatPrice(price: number): string {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
}

export function formatRate(rate: number) {
  return (
    new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(rate) + " %"
  );
}

export function formatSurface(surface: number, min = 0, max = 0) {
  return (
    new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    }).format(surface) + " m²"
  );
}

export function formatDateUStoFrench(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function formatDateUStoFrenchString(dateString: string) {
  return new Date(dateString).toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function formatDateUStoFrenchShortString(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const monthAndYear = date.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "short",
  });
  return `${day} ${monthAndYear}`;
}

export function formatSize(size: number) {
  const sizeInKb = size / 1024;

  if (sizeInKb > 1024) {
    return (
      new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(sizeInKb) + " Mo"
    );
  } else {
    return (
      new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(sizeInKb) + " Ko"
    );
  }
}

export function formatFloor(floor: string, suffix?: string): string {
  if (!isNaN(parseInt(floor))) {
    if (parseInt(floor) === 1) {
      return `1<sup>er</sup>${suffix ? " " + suffix : ""}`;
    } else {
      return `${floor} <sup>ème</sup>${suffix ? " " + suffix : ""}`;
    }
  }
  return floor;
}

export function sanitizeString(str: string) {
  // First, replace all spaces with dashes
  let cleaned = str.replace(/\s+/g, "-");

  // Then, remove all accents and special characters
  cleaned = cleaned.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Then, to lower cases
  cleaned = cleaned.toLowerCase();

  return cleaned;
}

export function stripTags(str?: string) {
  return str ? str.replace(/(<([^>]+)>)/gi, "") : "";
}

export function formatPlural(
  count: number,
  singular: string,
  plural?: string,
): string {
  if (count === 1) {
    return `${count} ${singular}`;
  } else {
    return `${count} ${plural || singular + "s"}`;
  }
}

export function transliterate(str: string) {
  const accentsMap = new Map([
    ["á", "a"],
    ["é", "e"],
    ["í", "i"],
    ["ó", "o"],
    ["ú", "u"],
    ["Á", "A"],
    ["É", "E"],
    ["Í", "I"],
    ["Ó", "O"],
    ["Ú", "U"],
    ["à", "a"],
    ["è", "e"],
    ["ì", "i"],
    ["ò", "o"],
    ["ù", "u"],
    ["À", "A"],
    ["È", "E"],
    ["Ì", "I"],
    ["Ò", "O"],
    ["Ù", "U"],
    ["ä", "a"],
    ["ë", "e"],
    ["ï", "i"],
    ["ö", "o"],
    ["ü", "u"],
    ["Ä", "A"],
    ["Ë", "E"],
    ["Ï", "I"],
    ["Ö", "O"],
    ["Ü", "U"],
    ["â", "a"],
    ["ê", "e"],
    ["î", "i"],
    ["ô", "o"],
    ["û", "u"],
    ["Â", "A"],
    ["Ê", "E"],
    ["Î", "I"],
    ["Ô", "O"],
    ["Û", "U"],
    ["ç", "c"],
    ["Ç", "C"],
    ["ñ", "n"],
    ["Ñ", "N"],
    ["ß", "ss"],
    ["ÿ", "y"],
    ["Ÿ", "Y"],
  ]);

  return str
    .split("")
    .map((char) => accentsMap.get(char) || char)
    .join("")
    .replace(/\s+/g, "_");
}
