function splitByLines(text: string): string[] {
  return text.replace(/\r\n/g, "\n").replace(/\r/g, "\n").split("\n");
}

/*
 * Transform a string into a hashmap.
 * @param text - The string to transform.
 * @returns The hashmap.
 *
 * From:
 *   "lorem|ipsum\r\ndolore|est\r\n";
 *
 * To:
 *    {
 *       "lorem": "ipsum",
 *       "dolore": "est"
 *    }
 */
export function hashMapFromString(text: string): Record<string, string> {
  const lines = splitByLines(text);
  const map: Record<string, string> = {};
  for (const line of lines) {
    const [key, value] = line.split("|");
    map[key] = value;
  }
  return map;
}
